import { Link } from "react-router-dom";
import { useEffect } from "react";

const posts = [
    {
        id: 1,
        title: "Key Ranking Factors in Amazon's New A10 Algorithm",
        href: "/blog/amazon-a10-algorithm",
        description:
            "Amazon's A10 algorithm is the sophisticated system that Amazon uses to rank products in its search results. This algorithm is an evolution from the previous A9 algorithm and includes several key features and factors that influence how products are ranked on the platform",
        imageUrl: "/img/A10_Graphic_Small.jpg",
        date: "Dec 12, 2023",
        datetime: "2023-12-12",
    },
    {
        id: 2,
        title: "Navigating the Competitive Amazon Marketplace: How Coupon Code Landing Pages Create a Winning Edge",
        href: "/blog/navigating-competition",
        description:
            "In the competitive arena of Amazon's marketplace, standing out is about more than just having a great product. It's about strategically positioning your brand in a way that not only ",
        imageUrl: "/img/coupon.png",
        date: "Nov 8, 2023",
        datetime: "2023-11-08",
    },
    {
        id: 3,
        title: "Revolutionizing Amazon Sales: The Strategic Packing Slip Approach to Amazon Sales with GoToMarket Tools",
        href: "/blog/packing-slip-approach",
        description:
            "In the competitive world of e-commerce, Amazon sellers are constantly on the lookout for innovative ways to drive sales and cultivate customer loyalty. A novel strategy that is creating a b",
        imageUrl: "/img/qr.png",
        date: "Nov 8, 2023",
        datetime: "2023-11-08",
    },
    {
        id: 4,
        title: "Driving External Traffic to Amazon Listings: A Game-Changer for On-Amazon SEO and Rankings",
        href: "/blog/external-traffic",
        description:
            "In today's digital marketplace, Amazon stands as a colossus, hosting millions of sellers and an even greater number of products. With such a vast array of options available to consumers",
        imageUrl: "/img/traffic.png",
        date: "Nov 8, 2023",
        datetime: "2023-11-08",
    },
    {
        id: 5,
        title: "Maximizing Sales and Customer Loyalty: The Power of Coupon Code Landing Pages for Amazon Sellers",
        href: "/blog/maximizing-sales-and-customer-loyalty",
        description:
            "In the ever-evolving landscape of online retail, Amazon sellers are continually searching for strategies to increase sales and build customer loyalty. One such tactic that is gaining traction is the use of coupon code landing pages.",
        imageUrl: "/img/max.png",
        date: "Nov 7, 2023",
        datetime: "2023-11-07",
    },
];

export default function Blog() {
    useEffect(() => {
        document.title = "Blog - gotomarket.tools";
    }, []);
    return (
        <div className="bg-white pb-24 pt-24 sm:pb-32 pt-24">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <p className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl text-gray-800">
                        From The Blog
                    </p>
                </div>
                <p className="mx-auto mt-2 max-w-2xl text-center text-lg leading-8 text-gray-600">
                    Learn how to grow your business with expert advice from the
                    GoToMarket team
                </p>
                <div className="mx-auto mt-24 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {posts.map((post) => (
                        <article
                            key={post.id}
                            className="flex flex-col items-start justify-between"
                        >
                            <div className="relative w-full">
                                <Link to={post.href}>
                                    <img
                                        src={post.imageUrl}
                                        alt=""
                                        className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                    />
                                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                </Link>
                            </div>
                            <div className="max-w-xl">
                                <div className="mt-8 flex items-center gap-x-4 text-xs">
                                    <time
                                        dateTime={post.datetime}
                                        className="text-gray-500"
                                    >
                                        {post.date}
                                    </time>
                                </div>
                                <div className="group relative">
                                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                        <Link to={post.href}>
                                            <span className="absolute inset-0" />
                                            {post.title}
                                        </Link>
                                    </h3>
                                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                                        {post.description}
                                    </p>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </div>
        </div>
    );
}
