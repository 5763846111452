import {
    LinkIcon,
    CurrencyDollarIcon,
    ChartPieIcon,
    ArrowPathIcon,
    LockClosedIcon,
    DevicePhoneMobileIcon,
} from "@heroicons/react/24/outline";

const features = [
    {
        name: "Dynamic Link Management",
        description:
            "Easily change the destination of your magic links after they're distributed, giving you ultimate flexibility.",
        icon: ArrowPathIcon,
    },
    {
        name: "Cost Efficiency",
        description:
            "Save on printing and advertising costs with easily shareable digital links.",
        icon: CurrencyDollarIcon,
    },
    {
        name: "Advanced Analytics",
        description:
            "Gain insights into user behavior and campaign performance with detailed analytics for every link.",
        icon: ChartPieIcon,
    },
    {
        name: "Enhanced Security",
        description:
            "Secure your content with magic links that can be set to expire or change after a certain number of uses.",
        icon: LockClosedIcon,
    },
    {
        name: "Seamless User Experience",
        description:
            "Provide a smooth and convenient experience for users by directing them exactly where they need to go with a single click.",
        icon: LinkIcon,
    },
    {
        name: "Mobile Optimization",
        description:
            "Reach your audience on-the-go with links that are perfect for mobile users.",
        icon: DevicePhoneMobileIcon,
    },
];

export default function FeaturesPanel5() {
    return (
        <div className="bg-white py-24 sm:py-32" id="ml">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <img
                        src="/img/link.svg"
                        className="h-12 w-12 mb-6 text-corporate"
                        alt="Magic Link Icon"
                    />
                    <h2 className="text-base font-semibold leading-7 text-corporate">
                        Link Simplified
                    </h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Magic Links for Streamlined Marketing
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-900">
                        Transform your marketing strategy with magic links.
                        GoToMarket's easy-to-use platform allows you to create,
                        manage, and track powerful short links for your
                        campaigns, driving sales and customer engagement with
                        just one click.
                    </p>
                </div>

                <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-900 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative pl-9">
                            <dt className="inline font-semibold text-gray-900">
                                <feature.icon
                                    className="absolute -left-1 top-1 h-6 w-6 text-corporate"
                                    aria-hidden="true"
                                />
                                {feature.name}
                            </dt>{" "}
                            <dd className="inline">{feature.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    );
}
