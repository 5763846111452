import React, { useEffect, useState } from "react";
import Spinner from "../Components/Spinner";

export default function Book() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Book a Demo - gotomarket.tools";
    }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);

        const handleCalendlyEvent = (e) => {
            if (e.data.event === "calendly.widget_load") {
                setLoading(false);
            }
        };

        window.addEventListener("message", handleCalendlyEvent);

        return () => {
            document.body.removeChild(script);
            window.removeEventListener("message", handleCalendlyEvent);
        };
    }, []);

    return (
        <div className="mb-16 relative">
            {loading && (
                <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    <Spinner />
                </div>
            )}
            <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/gotomarkettools/30min?primary_color=43749d"
                style={{ minWidth: "320px", height: "700px" }}
            ></div>
        </div>
    );
}
