import { useState, useEffect } from "react";
import axios from "axios";
import CountdownTimer from "../Components/CountdownTimer";
import TagManager from "react-gtm-module";
import ReCAPTCHA from "react-google-recaptcha";

export default function Create() {
    const [captchaValue, setCaptchaValue] = useState(null);
    const [asin, setAsin] = useState("");
    const [asinError, setAsinError] = useState("");
    const [layoutError, setLayoutError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [layout, setLayout] = useState("");
    const [uuid, setUuid] = useState("");
    const [email, setEmail] = useState("");
    const [emailSubmitted, setEmailSubmitted] = useState(false);

    useEffect(() => {
        document.title =
            "Create Landing Page - Your Amazon Landing Page Solution";
    }, []);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                page: "Create",
            },
        });
    }, []);

    const handleClick = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: "button_click",
                eventCategory: "User",
                eventAction: "Clicked Button",
            },
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!captchaValue) {
            alert("Please pass the captcha test before submitting.");
            return;
        }
        setIsLoading(true);

        if (!asin || !asin.match(/^[A-Z0-9]{10}$/)) {
            setAsinError("Please enter a valid Amazon ASIN.");
            setIsLoading(false);
            return;
        }

        setAsinError("");

        if (!layout) {
            setLayoutError("Please select a landing page style.");
            setIsLoading(false);
            return;
        }

        setLayoutError("");

        try {
            const response = await axios.post(
                "https://api.gotomarket.tools/api/landingpage",
                {
                    asin,
                    captcha: captchaValue,
                    layout,
                }
            );
            setResult(response.data);
            setPageId(response.data.pageId);
            setUuid(response.data.uuid);
        } catch (error) {
            console.error("Error fetching data:", error);
            setResult({ error: "Error fetching data" });
        }

        setIsLoading(false);
    };

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setEmailSubmitted(true);

        try {
            await axios.post(
                "https://api.gotomarket.tools/api/email/sendLandingPageEmail",
                {
                    customerEmail: email,
                    pageId: pageId,
                    uuid: uuid,
                }
            );
            // Keep the button disabled and change its text to "Email Sent!"
        } catch (error) {
            console.error("Error sending email:", error);
            alert("Failed to send email.");
            setEmailSubmitted(false); // Reset the flag in case of failure
        }
    };

    const handleRecaptcha = (value) => {
        setCaptchaValue(value);
    };

    return (
        <div
            className="mx-auto max-w-6xl sm:px-6 lg:px-8 sm:mb-24"
            id="generator"
        >
            <div className="mt-16 mb-8 p-4 sm:p-0 flex flex-col items-center justify-center">
                <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-corporate/10 hover:ring-corporate/20">
                    Fully-Responsive, High-Converting
                </div>
                <div className="mx-auto max-w-6xl text-center">
                    <h1 className="mt-2 text-center text-5xl  font-bold tracking-tight leading-tight sm:text-6xl lg:text-7xl text-gray-900">
                        Create Your Free Landing Page
                    </h1>
                </div>
                <p className="mx-auto mt-6 max-w-lg text-center text-xl text-gray-700 sm:max-w-3xl leading-loose">
                    Increase your Amazon sales and product rank by distributing
                    Amazon coupon codes through an external landing page.
                    Generate your free landing page below.
                </p>
            </div>
            <div className="relative shadow sm:overflow-hidden sm:rounded-2xl bg-corporate">
                <div
                    className={`relative px-6 py-8 sm:pt-12 sm:pb-0 lg:px-8 lg:pt-12 lg:pb-4 ${
                        pageId ? "lg:pb-0" : ""
                    }`}
                >
                    <h2 className="text-center text-2xl font-bold tracking-tight sm:text-3xl lg:text-4xl">
                        <span className="block text-white">
                            Create your Landing Page
                        </span>
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mx-auto mt-6 max-w-lg sm:max-w-3xl">
                            <input
                                type="text"
                                placeholder="Enter ASIN"
                                value={asin}
                                onChange={(e) => setAsin(e.target.value)}
                                className={`block w-full rounded-md py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 mb-2 ${
                                    asinError
                                        ? "border-red-600 ring-red-600 placeholder:text-red-600"
                                        : "border-0 ring-gray-300 placeholder:text-gray-400"
                                }`}
                            />
                            {asinError && (
                                <p className="text-white text-center">
                                    {asinError}
                                </p>
                            )}
                        </div>
                        <h3 className="text-center text-xl font-normal mt-4">
                            <span className="block text-white">
                                Choose your landing page style
                            </span>
                        </h3>
                        <div className="flex justify-center mt-2">
                            <button
                                type="button"
                                className={`px-4 py-2 rounded-md ${
                                    layout === "Minimal"
                                        ? "bg-gray-300 text-gray-700"
                                        : "bg-gray-200 text-gray-700"
                                } ${
                                    layoutError // Apply the error class conditionally
                                        ? "border-2 border-red-600 ring-red-600 placeholder:text-red-600"
                                        : ""
                                }`}
                                onClick={() => {
                                    setLayout("Minimal");
                                    setLayoutError(""); // Clear the layoutError when the button is clicked
                                }}
                            >
                                <img
                                    src="/img/min.svg"
                                    alt="Minimalist"
                                    className="block w-24 mb-2"
                                />
                                <p>Minimal</p>
                            </button>
                            <button
                                type="button"
                                className={`ml-4 px-4 py-2 rounded-md ${
                                    layout === "Detailed"
                                        ? "bg-gray-300 text-gray-700"
                                        : "bg-gray-200 text-gray-700"
                                } ${
                                    layoutError // Apply the error class conditionally
                                        ? "border-2 border-red-600 ring-red-600 placeholder:text-red-600"
                                        : ""
                                }`}
                                onClick={() => {
                                    setLayout("Detailed");
                                    setLayoutError(""); // Clear the layoutError when the button is clicked
                                }}
                            >
                                <img
                                    src="/img/max.svg"
                                    alt="Minimalist"
                                    className="block w-24 mb-2"
                                />
                                <p>Detailed</p>
                            </button>
                        </div>

                        {layoutError && (
                            <p className="text-white text-center">
                                {layoutError}
                            </p>
                        )}
                        <div
                            className={`sm:flex-row flex flex-col justify-center items-center gap-4 mt-6 mb-12 ${
                                pageId ? "mb-0" : ""
                            }`}
                        >
                            <ReCAPTCHA
                                sitekey="6Le1hT4nAAAAAEH620hQRpAZ-4VMd6ogc4UfwvvP"
                                onChange={handleRecaptcha}
                                className="order-1 sm:order-none"
                            />
                            <button
                                type="submit"
                                disabled={isLoading}
                                onClick={handleClick}
                                className="order-2 sm:order-none flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-12 py-6 text-base font-medium text-white shadow-sm hover:bg-orange-700"
                            >
                                {isLoading
                                    ? "Loading..."
                                    : "Generate Landing Page"}
                            </button>
                        </div>
                    </form>
                    {pageId && (
                        <div className="relative shadow sm:overflow-hidden rounded-2xl bg-gray-900 mt-4 p-8 sm:py-8 lg:px-8 lg:py-12 mb-4 flex flex-col items-center">
                            <h3 className="text-center text-3xl font-normal">
                                <span className="block text-white font-bold">
                                    Enter Email Address For Your Landing Page
                                    Link
                                </span>
                            </h3>
                            <form
                                onSubmit={handleEmailSubmit}
                                className="mt-4 max-w-2xl w-full sm:flex sm:items-center sm:justify-center"
                            >
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full min-w-0 appearance-none rounded-md py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm mb-2 sm:mb-0 sm:mr-4"
                                    disabled={emailSubmitted} // Disable input after email is sent
                                />
                                <button
                                    type="submit"
                                    disabled={emailSubmitted} // Disable button after email is sent
                                    className={`flex w-full sm:w-60 items-center justify-center rounded-md px-4 py-3 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-corporate ${
                                        emailSubmitted
                                            ? "bg-gray-500 text-white"
                                            : "bg-orange-600 hover:bg-orange-700 text-white"
                                    }`}
                                >
                                    {emailSubmitted
                                        ? "Email Sent!"
                                        : "Claim Landing Page"}
                                </button>
                            </form>
                            <div className="flex justify-center mt-4 text-white text-center">
                                <CountdownTimer
                                    initialTime={10 * 60}
                                    pageId={pageId}
                                    uuid={uuid}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
