import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";

const frequencies = [
    {
        value: "annually",
        label: "Annually",
        priceSuffix: ".99 / month",
        description: "Billed annually",
    },
    {
        value: "monthly",
        label: "Monthly",
        priceSuffix: ".99 / month",
        description: "Billed monthly",
    },
];
const tiers = [
    {
        name: "Starter",
        id: "starter",
        price: { monthly: "$29", annually: "$19" },
        description: "Great for sellers with less than 10 ASINs",
        features: [
            "3 Campaigns",
            "Unlimited Traffic",
            "Custom Domains",
            "Priority Support",
            "Amazon Attribution",
        ],
        featured: false,
        cta: "Sign Up",
    },
    {
        name: "Growth",
        id: "growth",
        price: { monthly: "$79", annually: "$49" },
        description: "Great for sellers with 10-50 ASINs",
        features: [
            "10 Campaigns",
            "Unlimited Traffic",
            "Unlimited Coupon Redemptions",
            "Custom Domains",
            "Priority Support",
        ],
        featured: false,
        cta: "Sign Up",
    },
    {
        name: "Enterprise",
        id: "enterprise",
        price: { monthly: "$159", annually: "$99" },
        description: "Great for sellers with 50+ ASINs",
        features: [
            "Unlimited Campaigns",
            "Unlimited Traffic",
            "Unlimited Coupon Redemptions",
            "Custom Domains",
            "Priority Support",
        ],
        cta: "Sign Up",
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function PricingComponent() {
    const [frequency, setFrequency] = useState(frequencies[0]);

    return (
        <div className="bg-white py-24 sm:py-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base font-semibold leading-7 text-corporate">
                        Pricing
                    </h2>
                    <p className="mt-2 text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl text-gray-800">
                        Pricing Plans For Sellers of All Sizes
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                    Gotomarket has plans for sellers of all sizes. Pick the plan
                    that's right for you below.
                </p>
                <div className="mt-16 flex justify-center">
                    <RadioGroup
                        value={frequency}
                        onChange={setFrequency}
                        className="grid grid-cols-2 bg-white gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-300"
                    >
                        <RadioGroup.Label className="sr-only">
                            Payment frequency
                        </RadioGroup.Label>
                        {frequencies.map((option) => (
                            <RadioGroup.Option
                                key={option.value}
                                value={option}
                                className={({ checked }) =>
                                    classNames(
                                        checked
                                            ? "bg-corporate text-white"
                                            : "text-gray-500",
                                        "cursor-pointer rounded-full px-2.5 py-1"
                                    )
                                }
                            >
                                <span>{option.label}</span>
                            </RadioGroup.Option>
                        ))}
                    </RadioGroup>
                </div>
                <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {tiers.map((tier) => (
                        <div
                            key={tier.id}
                            className={classNames(
                                tier.featured
                                    ? "bg-gray-900 ring-gray-900"
                                    : "ring-gray-200",
                                "bg-white rounded-3xl p-8 ring-1 xl:p-10"
                            )}
                        >
                            <h3
                                id={tier.id}
                                className={classNames(
                                    tier.featured
                                        ? "text-white"
                                        : "text-gray-900",
                                    "text-lg font-semibold leading-8"
                                )}
                            >
                                {tier.name}
                            </h3>
                            <p
                                className={classNames(
                                    tier.featured
                                        ? "text-gray-300"
                                        : "text-gray-600",
                                    "mt-4 text-sm leading-6"
                                )}
                            >
                                {tier.description}
                            </p>
                            <div className="mt-6 flex items-start gap-x-1">
                                {" "}
                                {/* Change this <p> to a <div> and adjust classes for alignment */}
                                <span
                                    className={classNames(
                                        tier.featured
                                            ? "text-white"
                                            : "text-gray-900",
                                        "text-5xl font-bold tracking-tight"
                                    )}
                                >
                                    {typeof tier.price === "string"
                                        ? tier.price
                                        : tier.price[frequency.value]}
                                </span>
                                {typeof tier.price !== "string" ? (
                                    <div /* Use a <div> instead of <span> for block-level behavior */
                                        className={classNames(
                                            tier.featured
                                                ? "text-gray-300"
                                                : "text-gray-600",
                                            "text-sm font-semibold leading-6 ml-0.5"
                                        )}
                                    >
                                        {frequency.priceSuffix}
                                        {/* Add this part to display "billed monthly" or "billed annually" */}
                                        <div className="text-sm font-light text-gray-600">
                                            {" "}
                                            {frequency.description}{" "}
                                        </div>{" "}
                                        {/* Adjust as needed */}
                                    </div>
                                ) : null}
                            </div>
                            <a
                                href="https://app.gotomarket.tools/signup"
                                aria-describedby={tier.id}
                                className={classNames(
                                    tier.featured
                                        ? "bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white"
                                        : "bg-corporate text-white shadow-sm hover:bg-corporate focus-visible:outline-corporate",
                                    "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full"
                                )}
                            >
                                {tier.cta}
                            </a>
                            <ul
                                className={classNames(
                                    tier.featured
                                        ? "text-gray-300"
                                        : "text-gray-600",
                                    "mt-8 space-y-3 text-sm leading-6 xl:mt-8"
                                )}
                            >
                                {tier.features.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                        <CheckIcon
                                            className={classNames(
                                                tier.featured
                                                    ? "text-white"
                                                    : "text-corporate",
                                                "h-6 w-5 flex-none"
                                            )}
                                            aria-hidden="true"
                                        />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
