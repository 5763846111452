import { Menu, Transition } from "@headlessui/react";
import { useState, Fragment, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function Navigation() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [demoMenuVisible, setDemoMenuVisible] = useState(false);

    const closeDropdownTimeoutRef = useRef();

    const showDemoMenu = () => {
        clearTimeout(closeDropdownTimeoutRef.current);
        setDemoMenuVisible(true);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    const hideDemoMenu = () => {
        closeDropdownTimeoutRef.current = setTimeout(() => {
            setDemoMenuVisible(false);
        }, 100); // 100ms delay for leaving the menu
    };

    return (
        <header className="bg-gray-900 z-50">
            <nav
                className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
                aria-label="Global"
            >
                <div className="flex items-center gap-x-12">
                    <Link to="/" className="-m-1.5 p-1.5 cursor-pointer">
                        <span className="sr-only">Your Company</span>
                        <img
                            className="h-8 w-auto"
                            src="/img/logomark.png"
                            alt="GoToMarket Logo"
                        />
                    </Link>
                    <div className="hidden lg:flex lg:gap-x-12">
                        <Link
                            to="features"
                            className="text-sm font-semibold leading-6 text-white"
                        >
                            Features
                        </Link>
                        <Menu
                            as="div"
                            className="relative inline-block text-left"
                            onMouseEnter={showDemoMenu}
                            onMouseLeave={hideDemoMenu}
                        >
                            <Menu.Button
                                as={Link}
                                to="/demo"
                                className="text-sm font-semibold leading-6 text-white"
                            >
                                Demo
                            </Menu.Button>

                            <Transition
                                as={Fragment}
                                show={demoMenuVisible}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className="absolute z-10 mt-2 w-48 origin-top-left rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    onMouseEnter={showDemoMenu}
                                    onMouseLeave={hideDemoMenu}
                                >
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="https://app.gotomarket.tools/landingpage/6543df4250e209263db1372b"
                                                target="_blank"
                                                rel="noreferrer"
                                                className={`block px-4 py-2 text-sm text-gray-700 ${
                                                    active ? "bg-gray-100" : ""
                                                }`}
                                            >
                                                Minimal
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="https://app.gotomarket.tools/landingpage/6543df6750e209263db13738"
                                                target="_blank"
                                                rel="noreferrer"
                                                className={`block px-4 py-2 text-sm text-gray-700 ${
                                                    active ? "bg-gray-100" : ""
                                                }`}
                                            >
                                                Detailed
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="/create"
                                                rel="noreferrer"
                                                className={`block px-4 py-2 text-sm text-gray-700 ${
                                                    active ? "bg-gray-100" : ""
                                                }`}
                                            >
                                                Create Landing Page
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <Link
                                                to="book"
                                                className={`block px-4 py-2 text-sm text-gray-700 ${
                                                    active ? "bg-gray-100" : ""
                                                }`}
                                            >
                                                Book a Demo
                                            </Link>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                        <Link
                            to="pricing"
                            className="text-sm font-semibold leading-6 text-white"
                        >
                            Pricing
                        </Link>
                        <Link
                            to="blog"
                            className="text-sm font-semibold leading-6 text-white"
                        >
                            Blog
                        </Link>
                        <Link
                            to="contact"
                            className="text-sm font-semibold leading-6 text-white"
                        >
                            Contact
                        </Link>
                    </div>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex">
                    <Link
                        to="https://app.gotomarket.tools/login"
                        class="text-base font-medium text-white py-2"
                    >
                        Login
                    </Link>
                    <Link
                        to="https://app.gotomarket.tools/signup"
                        class="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm"
                    >
                        Sign up
                    </Link>
                </div>
            </nav>
            <Dialog
                as="div"
                className="lg:hidden"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
            >
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to="#" className="-m-1.5 p-1.5">
                            <img
                                className="h-8 w-auto"
                                src="/img/logomark.png"
                                alt="GoToMarket Logo"
                            />
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <div onClick={closeMobileMenu}>
                                    <Link
                                        to="features"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Features
                                    </Link>
                                </div>
                                <div onClick={closeMobileMenu}>
                                    <Link
                                        to="https://app.gotomarket.tools/landingpage/6543df6750e209263db13738"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        View Demo Landing Page
                                    </Link>
                                </div>
                                <div onClick={closeMobileMenu}>
                                    <Link
                                        to="https://gotomarket.tools/book"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        rel="noreferrer"
                                    >
                                        Book A Demo
                                    </Link>
                                </div>
                                <div onClick={closeMobileMenu}>
                                    <Link
                                        to="pricing"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Pricing
                                    </Link>
                                </div>
                                <div onClick={closeMobileMenu}>
                                    <Link
                                        to="blog"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Blog
                                    </Link>
                                </div>
                            </div>
                            <div className="py-6">
                                <div onClick={closeMobileMenu}>
                                    <Link
                                        to="https://app.gotomarket.tools/login"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Log in
                                    </Link>
                                </div>
                                <div onClick={closeMobileMenu}>
                                    <Link
                                        to="https://app.gotomarket.tools/signup"
                                        className="-mx-3 bg-orange-600 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-orange-700"
                                    >
                                        Sign up
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
}
