import { Link } from "react-router-dom";
import Subfooter from "./Subfooter";

export default function CallToAction() {
    return (
        <>
            <div className="bg-corporate">
                <div className="px-6 py-16 sm:px-6 sm:py-24 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            Start Increasing Your Amazon Rank Today
                        </h2>
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-white">
                            Sign up today for your free 30-day trial.
                        </p>
                        <div className="mt-8 flex items-center justify-center gap-x-6">
                            <Link
                                to="https://app.gotomarket.tools/signup"
                                className="rounded-md bg-orange-600 px-12 py-6 text-lg font-semibold text-white shadow-sm hover:bg-orange-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >
                                Sign Up
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Subfooter />
        </>
    );
}
