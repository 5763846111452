export default function Privacy() {
    return (
        <>
            <div className="container mx-auto max-w-7xl px-6 py-8">
                <h1 className="text-3xl font-bold underline mb-4">
                    PRIVACY POLICY
                </h1>
                <p className="text-sm mb-4">Last updated November 08, 2023</p>
                <p className="text-sm mb-4">
                    This privacy notice for Go To Market Tools, Inc. ("
                    <strong>we</strong>","<strong>us</strong>,” or “
                    <strong>our</strong>’), describes how and why we might
                    collect, store, use, and/or share ("<strong>process</strong>
                    ") your information when you use our services ("
                    <strong>Services</strong>"), such as when you:
                </p>
                <ul className="list-disc mb-4">
                    <li className="text-sm ml-5">
                        Visit our website at https:/www.gotomarket.tools, or any
                        website of ours that links to this privacy notice
                    </li>
                    <li className="text-sm ml-5">
                        Engage with us in other related ways, including any
                        sales, marketing, or events
                    </li>
                </ul>
                <p className="text-sm mb-4">
                    <strong>Questions or concerns?</strong> Reading this privacy
                    notice will help you understand your privacy rights and
                    choices. If you do not agree with our policies and
                    practices, please do not use our Services. If you stil have
                    any questions or concerns, please contact us at
                    info@gotomarket tools
                </p>
                <h3 className="text-xl font-semibold mt-4 mb-2">
                    SUMMARY OF KEY POINTS
                </h3>
                <p className="text-sm mb-4">
                    <strong>
                        This summary provides key points from our privacy
                        notice, but you can find out more details about any of
                        these topics by clicking the link following each key
                        point or by using our table of contents below to find
                        the section you are looking for.
                    </strong>
                </p>
                <p className="text-sm mb-4">
                    <strong>What personal information do we process?</strong>{" "}
                    When you visit, use, or navigate our Services, we may
                    process personal information depending on how you interact
                    with us and the Services, the choices you make, and the
                    products and features you use. Learn more about personal
                    information you disclose to us.
                </p>
                <p className="text-sm mb-4">
                    <strong>
                        Do we process any sensitive personal information?
                    </strong>{" "}
                    We do not process sensitive personal information.
                </p>
                <p className="text-sm mb-4">
                    <strong>
                        Do we receive any information from third parties?
                    </strong>{" "}
                    We do not receive any information from third parties.
                </p>
                <p className="text-sm mb-4">
                    <strong>How do we process your information?</strong> We
                    process your information to provide, improve, and administer
                    our Services, communicate with you, for security and fraud
                    prevention, and to comply with law. We may also process your
                    information for other purposes with your consent. We process
                    your information only when we have a valid legal reason to
                    do so. Learn more about how we process your information
                </p>
                <p className="text-sm mb-4">
                    <strong>
                        In what situations and with which parties do we share
                        personal information?
                    </strong>{" "}
                    We may share information in specific situations and with
                    specific third parties. Learn more about when and with whom
                    we share your personal information
                </p>
                <p className="text-sm mb-4">
                    <strong>How do we keep your information safe?</strong> We
                    have organizational and technical processes and procedures
                    in place to protect your personal information. However, no
                    electronic transmission over the internet or information
                    storage technology can be guaranteed to be 100% secure, so
                    we cannot promise or guarantee that hackers, cybercriminals,
                    or other unauthorized third parties will not be able to
                    defeat our security and improperly collect, access, steal,
                    or modify your information. Learn more about how we keep
                    your Information safe.
                </p>
                <p className="text-sm mb-4">
                    <strong>What are your rights?</strong> Depending on where
                    you are located geographically, the applicable privacy law
                    may mean you have certain rights regarding your personal
                    information. Learn more about your privacy rights
                </p>
                <p className="text-sm mb-4">
                    <strong>How do you exercise your rights?</strong> The
                    easiest way to exercise your rights is by visiting
                    www.gotomarket.tools, or by contacting us. We will consider
                    and act upon any request in accordance with applicable data
                    protection laws.
                </p>
                <p className="text-sm mb-4">
                    Want to learn more about what we do with any information we
                    collect? Review the privacy notice in full
                </p>
                <h2 className="text-2xl font-semibold mt-4 mb-2">
                    TABLE OF CONTENTS
                </h2>
                <ol className="list-decimal">
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#1">
                            WHAT INFORMATION DO WE COLLECT?
                        </a>
                    </li>
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#2">
                            HOW DO WE PROCESS YOUR INFORMATION?
                        </a>
                    </li>
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#3">
                            WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                            PERSONAL INFORMATION?
                        </a>
                    </li>
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#4">
                            WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                            INFORMATION?
                        </a>
                    </li>
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#5">
                            DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                        </a>
                    </li>
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#6">
                            HOW LONG DO WE KEEP YOUR INFORMATION?
                        </a>
                    </li>
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#7">
                            HOW DO WE KEEP YOUR INFORMATION SAFE?
                        </a>
                    </li>
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#8">
                            DO WE COLLECT INFORMATION FROM MINORS?
                        </a>
                    </li>
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#9">
                            WHAT ARE YOUR PRIVACY RIGHTS?
                        </a>
                    </li>
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#10">
                            CONTROLS FOR DO-NOT-TRACK FEATURES
                        </a>
                    </li>
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#11">
                            DO WE MAKE UPDATES TO THIS NOTICE?
                        </a>
                    </li>
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#12">
                            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        </a>
                    </li>
                    <li className="ml-7">
                        <a className="text-blue-500 underline" href="#13">
                            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                            COLLECT FROM YOU?
                        </a>
                    </li>
                </ol>
                <h2 className="text-2xl font-semibold mt-4 mb-2" id="1">
                    1. WHAT INFORMATION DO WE COLLECT?
                </h2>
                <h3 className="text-xl font-semibold mt-4 mb-2">
                    Personal information you disclose to us
                </h3>
                <p className="text-sm mb-4">
                    <strong>In Short</strong>: We collect personal information
                    that you provide to us.
                </p>
                <p className="text-sm mb-4">
                    We collect personal information that you voluntarily provide
                    to us when you register on the Services, express an interest
                    in obtaining information about us or our products and
                    Services, when you participate in activities on the
                    Services, or otherwise when you contact us.
                </p>
                <p className="text-sm mb-4">
                    <strong>Personal Information Provided by You</strong>. The
                    personal information that we collect depends on the context
                    of your interactions with us and the Services, the choices
                    you make, and the products and features you use. The
                    personal information we collect may include the following:
                </p>
                <ul className="list-disc mb-4">
                    <li className="text-sm ml-5"> names</li>

                    <li className="text-sm ml-5"> phone numbers</li>

                    <li className="text-sm ml-5"> email addresses</li>

                    <li className="text-sm ml-5"> usernames</li>

                    <li className="text-sm ml-5"> passwords</li>

                    <li className="text-sm ml-5"> debit/credit card numbers</li>
                </ul>
                <p className="text-sm mb-4">
                    <strong>Sensitive Information</strong>. We do not process
                    sensitive information.
                </p>
                <p className="text-sm mb-4">
                    <strong>Payment Data</strong>. We may collect data necessary
                    to process your payment if you make purchases, such as your
                    payment instrument number, and the security code associated
                    with your payment instrument. All payment data Is stored by
                    Stripe. You may find their privacy notice link(s) here:
                    https:/istripe.com/privacy.
                </p>
                <p className="text-sm mb-4">
                    All personal information that you provide to us must be
                    true, complete, and accurate, and you must notify us of any
                    changes to such personal information.
                </p>
                <h3 className="text-xl font-semibold mt-4 mb-2">
                    Information automatically collected
                </h3>
                <p className="text-sm mb-4">
                    <strong>In Short</strong>: Some information — such as your
                    Internet Protocol (IP) address and/or browser and device
                    characteristics — is collected automatically when you visit
                    our Services.
                </p>
                <p className="text-sm mb-4">
                    We automatically collect certain information when you visit,
                    use, or navigate the Services. This information does not
                    reveal your specific identity (like your name or contact
                    Information) but may include device and usage information,
                    such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs,
                    device name, country, location, information about how and
                    when you use our Services, and other technical information.
                    This information is primarily needed to maintain the
                    security and operation of our Services, and for our internal
                    analytics and reporting purposes.
                </p>
                <p className="text-sm mb-4">
                    Like many businesses, we also collect information through
                    cookies and similar technologies.
                </p>
                <p className="text-sm mb-4">
                    The information we collect includes:
                </p>
                <ul className="list-disc mb-4">
                    <li className="text-sm ml-5">
                        Log and Usage Data. Log and usage data is
                        service-related, diagnostic, usage, and performance
                        information our servers automatically collect when you
                        access or use our Services and which we record in log
                        files. Depending on how you interact with us, this log
                        data may include your IP address, device information,
                        browser type, and settings and information about your
                        activity in the Services (such as the date/time stamps
                        associated with your usage, pages and files viewed,
                        searches, and other actions you take such as which
                        features you use), device event information (such as
                        system activity, error reports (sometimes called "crash
                        dumps"), and hardware settings)
                    </li>
                    <li className="text-sm ml-5">
                        Device Data. We collect device data such as information
                        about your computer, phone, tablet, or other device you
                        use to access the Services. Depending on the device
                        used, this device data may include information such as
                        your IP address (or proxy server), device and
                        application identification numbers, location, browser
                        type, hardware model, Internet service provider and/or
                        mobile carrier, operating system, and system
                        configuration information.
                    </li>
                    <li className="text-sm ml-5">
                        Location Data. We collect location data such as
                        information about your device's location, which can be
                        either precise or imprecise. How much information we
                        collect depends on the type and settings of the device
                        you use to access the Services. For example, we may use
                        GPS and other technologies to collect geolocation data
                        that tells, Us your current location (based on your IP
                        address). You can opt out of allowing us to collect this
                        information either by refusing access to the information
                        or by disabling your Location setting on your device.
                        However, if you choose to opt out, you may not be able
                        to use certain aspects of the Services.
                    </li>
                </ul>
                <h2 className="text-2xl font-semibold mt-4 mb-2" id="2">
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                </h2>
                <p className="text-sm mb-4">
                    <strong>In Short:</strong> We process your information to
                    provide, improve, and administer our Services, communicate
                    with you, for security and fraud prevention, and to comply
                    with law. We may also process your information for other
                    purposes with your consent.
                </p>
                <p className="text-sm mb-4">
                    We process your personal information for a variety of
                    reasons, depending on how you interact with our Services,
                    including:
                </p>
                <ul className="list-disc mb-4">
                    <li className="text-sm ml-5">
                        <strong>
                            To facilitate account creation and authentication
                            and otherwise manage user accounts
                        </strong>
                        . We may process your information so you can create and
                        log in to your account, as well as keep your account in
                        working order.
                    </li>
                    <li className="text-sm ml-5">
                        <strong>
                            To deliver and facilitate delivery of services to
                            the user
                        </strong>
                        . We may process your information to provide you with
                        the requested service.
                    </li>

                    <li className="text-sm ml-5">
                        <strong>
                            To send administrative information to you
                        </strong>
                        . We may process your information to send you details
                        about our products and services, changes to our terms
                        and policies, and other similar information.
                    </li>

                    <li className="text-sm ml-5">
                        <strong>
                            To send you marketing and promotional communications
                        </strong>
                        . We may process the personal information you send to us
                        for our marketing purposes, if this is in accordance
                        with your marketing preferences. You can opt out of our
                        marketing emails at any time. For more information, see
                        "WHAT ARE YOUR PRIVACY RIGHTS?” below.
                    </li>

                    <li className="text-sm ml-5">
                        <strong>To deliver targeted advertising to you</strong>.
                        We may process your information to develop and display
                        personalized content and advertising tailored to your
                        interests, location, and more.
                    </li>

                    <li className="text-sm ml-5">
                        <strong>To protect our Services</strong>. We may process
                        your information as part of our efforts to keep our
                        Services safe and secure, including fraud monitoring and
                        prevention.
                    </li>

                    <li className="text-sm ml-5">
                        <strong>
                            To evaluate and improve our Services, products,
                            marketing, and your experience
                        </strong>
                        . We may process your information when we believe it is
                        necessary to identify usage trends, determine the
                        effectiveness of our promotional campaigns, and to
                        evaluate and improve our Services, products, marketing,
                        and your experience.
                    </li>

                    <li className="text-sm ml-5">
                        <strong>To identify usage trends</strong>. We may
                        process information about how you use our Services to
                        better understand how they are being used so we can
                        improve them
                    </li>

                    <li className="text-sm ml-5">
                        <strong>
                            To determine the effectiveness of our marketing and
                            promotional campaigns
                        </strong>
                        . We may process your information to better understand
                        how to provide marketing and promotional campaigns that
                        are most relevant to you.
                    </li>

                    <li className="text-sm ml-5">
                        <strong>To comply with our legal obligations</strong>.
                        We may process your information to comply with our legal
                        obligations, respond to legal requests, and exercise,
                        establish, or defend our legal rights.
                    </li>
                </ul>
                <h2 className="text-2xl font-semibold mt-4 mb-2" id="3">
                    3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                    INFORMATION?
                </h2>
                <p className="text-sm mb-4">
                    In Short: We only process your personal information when we
                    believe it is necessary and we have a valid legal reason
                    (i.e, legal basis) to do so under applicable law, like with
                    your consent, to comply with laws, to provide you with
                    services to enter into or fulfil our contractual
                    obligations, to protect your rights, or to fulfill our
                    legitimate business interests.
                </p>
                <p className="text-sm mb-4">
                    <strong>
                        If you are located in Canada, this section applies to
                        you.
                    </strong>
                </p>
                <p className="text-sm mb-4">
                    We may process your information if you have given us
                    specific permission (i.¢., express consent) to use your
                    personal information for a specific purpose, or in
                    situations where your permission can be inferred (i.e.,
                    implied consent). You can withdraw your consent at any time.
                </p>
                <p className="text-sm mb-4">
                    In some exceptional cases, we may be legally permitted under
                    applicable law to process your information without your
                    consent, including, for example:
                </p>
                <p className="text-sm mb-4">
                    If collection is clearly in the interests of an individual
                    and consent cannot be obtained in a timely way
                </p>
                <ul className="list-disc mb-4">
                    <li className="text-sm ml-5">
                        For investigations and fraud detection and prevention
                    </li>

                    <li className="text-sm ml-5">
                        For business transactions provided certain conditions
                        are met
                    </li>

                    <li className="text-sm ml-5">
                        If it is contained in a witness statement and the
                        collection is necessary to assess, process, or settle an
                        insurance claim
                    </li>

                    <li className="text-sm ml-5">
                        For identifying injured, ill, or deceased persons and
                        communicating with next of kin
                    </li>

                    <li className="text-sm ml-5">
                        If we have reasonable grounds to believe an individual
                        has been, is, or may be victim of financial abuse
                    </li>

                    <li className="text-sm ml-5">
                        If it is reasonable to expect collection and use with
                        consent would compromise the availability or the
                        accuracy of the information and the collection is
                        reasonable for purposes related to investigating a
                        breach of an agreement or a contravention of the laws of
                        Canada or a province
                    </li>

                    <li className="text-sm ml-5">
                        If disclosure is required to comply with a subpoena,
                        warrant, court order, or rules of the court relating to
                        the production of records
                    </li>

                    <li className="text-sm ml-5">
                        If it was produced by an individual in the course of
                        their employment, business, or profession and the
                        collection is consistent with the purposes for which the
                        information was produced
                    </li>

                    <li className="text-sm ml-5">
                        If the collection is solely for journalistic, artistic,
                        or literary purposes
                    </li>

                    <li className="text-sm ml-5">
                        If the information is publicly available and is
                        specified by the regulations
                    </li>
                </ul>
                <h2 className="text-2xl font-semibold mt-4 mb-2" id="4">
                    4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </h2>
                <p className="text-sm mb-4">
                    <strong>In Short</strong>: We may share information in
                    specific situations described in this section and/or with
                    the following third parties.
                </p>
                <p className="text-sm mb-4">
                    We may need to share your personal information in the
                    following situations:
                </p>
                <ul className="list-disc mb-4">
                    <li className="text-sm ml-5">
                        <strong>Business Transfers</strong>. We may share or
                        transfer your information in connection with, or during
                        negotiations of, any merger, sale of company assets,
                        financing, or acquisition of all or a portion of our
                        business to another company.
                    </li>
                    <li className="text-sm ml-5">
                        <strong>Business Partners</strong>. We may share your
                        information with our business partners to offer you
                        certain products, services, or promotions.
                    </li>
                </ul>
                <h2 className="text-2xl font-semibold mt-4 mb-2" id="5">
                    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </h2>
                <p className="text-sm mb-4">
                    <strong>In Short</strong>: We may use cookies and other
                    tracking technologies to collect and store your information.
                </p>
                <p className="text-sm mb-4">
                    We may use cookies and similar tracking technologies (like
                    web beacons and pixels) to access or store information.
                    Specific information about how we use such technologies and
                    how you can refuse certain cookies is set out in our Cookie
                    Notice.
                </p>
                <h2 className="text-2xl font-semibold mt-4 mb-2" id="6">
                    6. HOW LONG DO WE KEEP YOUR INFORMATION?
                </h2>
                <p className="text-sm mb-4">
                    <strong>In Short</strong>: We keep your information for as
                    long as necessary to fulfil the purposes outlined in this
                    privacy notice unless otherwise required by law.
                </p>
                <p className="text-sm mb-4">
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this privacy
                    notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting, or other legal
                    requirements). No purpose in this notice will require us
                    keeping your personal information for longer than twelve
                    (12) months past the termination of the user's account.
                </p>
                <p className="text-sm mb-4">
                    When we have no ongoing legitimate business need to process
                    your personal information, we will either delete or
                    anonymize such information, or, if this is not possible (for
                    example, because your personal information has been stored
                    in backup archives), then we will securely store your
                    personal information and isolate it from any further
                    processing Until deletion is possible.
                </p>
                <h2 className="text-2xl font-semibold mt-4 mb-2" id="7">
                    7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </h2>
                <p className="text-sm mb-4">
                    <strong>In Short</strong>: We aim to protect your personal
                    information through a system of organizational and technical
                    security measures.
                </p>
                <p className="text-sm mb-4">
                    We have implemented appropriate and reasonable technical and
                    organizational security measures designed to protect the
                    security of any personal information we process. However,
                    despite our safeguards and efforts to secure your
                    information, no electronic transmission over the Internet or
                    information storage technology can be guaranteed to be 100%
                    secure, so we cannot promise or guarantee that hackers,
                    cybercriminals, or other unauthorized third parties will not
                    be able to defeat our security and improperly collect,
                    access, steal, or modify your information. Although we will
                    do our best to protect your personal information,
                    transmission of personal information to and from our
                    Services Is at your own risk. You should only access the
                    Services within a secure environment.
                </p>
                <h2 className="text-2xl font-semibold mt-4 mb-2" id="8">
                    8. DO WE COLLECT INFORMATION FROM MINORS?
                </h2>
                <p className="text-sm mb-4">
                    <strong>In Short</strong>: We do not knowingly collect data
                    from or market to children under 18 years of age.
                </p>
                <p className="text-sm mb-4">
                    We do not knowingly solicit data from or market to children
                    under 18 years of age. By using the Services, you represent
                    that you are at least 18 or that you are the parent or
                    guardian of such a minor and consent to such minor
                    dependent's use of the Services. If we learn that personal
                    information from users less than 18 years of age has been
                    collected, we will deactivate the account and take
                    reasonable measures to promptly delete such data from our
                    records. If you become aware of any data we may have
                    collected from children under age 18, please contact us at
                    info@gotomarket.tools.
                </p>
                <h2 className="text-2xl font-semibold mt-4 mb-2" id="9">
                    9. WHAT ARE YOUR PRIVACY RIGHTS?
                </h2>
                <p className="text-sm mb-4">
                    <strong>In Short</strong>: In some regions, such as Canada,
                    you have rights that allow you greater access to and control
                    over your personal information. You may review, change, or
                    terminate your account at any time.
                </p>
                <p className="text-sm mb-4">
                    In some regions (like Canada), you have certain rights under
                    applicable data protection laws. These may include the right
                    (i) to request access and obtain a copy of your personal
                    information, (ii) to request rectification or erasure; (il)
                    to restrict the processing of your personal information;
                    (iv) if applicable, to data portability; and (v) not to be
                    subject to automated decision-making. In certain
                    circumstances, you may also have the right to object to the
                    processing of your personal information. You can make such a
                    request by contacting us by using the contact details
                    provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
                    NOTICE?” below.
                </p>
                <p className="text-sm mb-4">
                    We will consider and act upon any request in accordance with
                    applicable data protection laws.
                </p>
                <p className="text-sm mb-4">
                    <strong>Withdrawing your consent</strong>: If we are relying
                    on your consent to process your personal information, which
                    may be express and/or implied consent depending on the
                    applicable law, you have the right to withdraw your consent
                    at any time. You can withdraw your consent at any time by
                    contacting us by using the contact details provided in the
                    section HOW CAN YOU CONTACT US ABOUT THIS NOTICE? below
                </p>
                <p className="text-sm mb-4">
                    However, please note that this will not affect the
                    lawfulness of the processing before its withdrawal nor, when
                    applicable law allows, will it affect the processing of your
                    personal Information conducted in reliance on lawful
                    processing grounds other than consent.
                </p>
                <p className="text-sm mb-4">
                    Opting out of marketing and promotional communications: You
                    can unsubscribe from our marketing and promotional
                    communications at any time by clicking on the unsubscribe
                    link in the emails that we send, replying "STOP" or
                    "UNSUBSCRIBE" to the SMS messages that we send, or by
                    contacting us using the details provided in the section "HOW
                    CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then
                    be removed from the marketing lists. However, we may still
                    communicate with you — for example, to send you
                    service-related messages that are necessary for the
                    administration and use of your account, to respond to
                    service requests, or for other non-marketing purposes.
                </p>
                <h3 className="text-xl font-semibold mt-4 mb-2">
                    Account Information
                </h3>
                <p className="text-sm mb-4">
                    If you would at any time like to review or change the
                    information in your account or terminate your account, you
                    can:
                </p>
                <ul className="list-disc mb-4">
                    <li className="text-sm ml-5">
                        Contact us using the contact information provided
                    </li>
                </ul>
                <p className="text-sm mb-4">
                    Upon your request to terminate your account, we will
                    deactivate or delete your account and information from our
                    active databases. However, we may retain some information in
                    our files to prevent fraud, troubleshoot problems, assist
                    with any investigations, enforce our legal terms and/or
                    comply with applicable legal requirements.
                </p>
                <p className="text-sm mb-4">
                    <strong>Cookies and similar technologies</strong>: Most Web
                    browsers are set to accept cookies by default. If you
                    prefer, you can usually choose to set your browser to remove
                    cookies and to reject cookies. If you choose to remove
                    cookies or reject cookies, this could affect certain
                    features or services of our Services
                </p>
                <p className="text-sm mb-4">
                    If you have questions or comments about your privacy rights,
                    you may email us at info@gotomarket.tools.
                </p>
                <h2 className="text-2xl font-semibold mt-4 mb-2" id="10">
                    10. CONTROLS FOR DO-NOT-TRACK FEATURES
                </h2>
                <p className="text-sm mb-4">
                    Most web browsers and some mobile operating systems and
                    mobile applications include a Do-Not-Track ("DNT") feature
                    or setting you can activate to signal your privacy
                    preference not to have data about your online browsing
                    activities monitored and collected. At this stage no uniform
                    technology standard for recognizing and implementing DNT
                    signals has been finalized. As such, we do not currently
                    respond to DNT browser signals or any other mechanism that
                    automatically communicates your choice not to be tracked
                    online. If a standard for online tracking is adopted that we
                    must follow in the future, we will inform you about that
                    practice in a revised version of this privacy notice.
                </p>

                <h2 className="text-2xl font-semibold mt-4 mb-2" id="11">
                    11. DO WE MAKE UPDATES TO THIS NOTICE?
                </h2>
                <p className="text-sm mb-4">
                    In Short: Yes, we will update this notice as necessary to
                    stay compliant with relevant laws.
                </p>
                <p className="text-sm mb-4">
                    We may update this privacy notice from time to time. The
                    updated version will be indicated by an updated "Revised"
                    date and the updated version will be effective as soon as it
                    is accessible. If we make material changes to this privacy
                    notice, we may notify you either by prominently posting a
                    notice of such changes or by directly sending you a
                    notification, We encourage you to review this privacy notice
                    frequently to be informed of how we are protecting your
                    information.
                </p>
                <h2 className="text-2xl font-semibold mt-4 mb-2" id="12">
                    12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </h2>
                <p className="text-sm mb-4">
                    If you have questions or comments about this notice, you may
                    email us at info@gotomarket.tools
                </p>
                <h2 className="text-2xl font-semibold mt-4 mb-2" id="13">
                    13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                    COLLECT FROM YOU?
                </h2>
                <p className="text-sm mb-4">
                    You have the right to request access to the personal
                    information we collect from you, change that information, or
                    delete it. To request to review, update, or delete your
                    personal Information, please visit: www.gotomarket tools.
                </p>
            </div>
        </>
    );
}
