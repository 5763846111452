import { useState, useEffect } from "react";

export default function Contact() {
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = "Contact - gotomarket.tools";
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form action
        setIsLoading(true);
        const formData = new FormData(event.target);
        const jsonData = Object.fromEntries(formData.entries());

        try {
            // This is where you'd call your backend API endpoint
            const response = await fetch(
                "https://api.gotomarket.tools/api/email",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(jsonData),
                }
            );

            if (response.ok) {
                setSubmissionStatus("success");
            } else {
                setSubmissionStatus("error");
            }
        } catch (error) {
            console.error("Error sending email:", error);
            setSubmissionStatus("error");
        }
        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="relative isolate bg-white">
                <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                    <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
                        <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                                <svg
                                    className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                                    aria-hidden="true"
                                >
                                    <defs>
                                        <pattern
                                            id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                                            width={200}
                                            height={200}
                                            x="100%"
                                            y={-1}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <path
                                                d="M130 200V.5M.5 .5H200"
                                                fill="none"
                                            />
                                        </pattern>
                                    </defs>
                                    <rect
                                        width="100%"
                                        height="100%"
                                        strokeWidth={0}
                                        fill="white"
                                    />
                                    <svg
                                        x="100%"
                                        y={-1}
                                        className="overflow-visible fill-gray-50"
                                    >
                                        <path
                                            d="M-470.5 0h201v201h-201Z"
                                            strokeWidth={0}
                                        />
                                    </svg>
                                    <rect
                                        width="100%"
                                        height="100%"
                                        strokeWidth={0}
                                        fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                                    />
                                </svg>
                            </div>
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                    Connect With Our Team
                                </h2>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Have questions about how GoToMarket Tools
                                    can help increase your eCommerce sales and
                                    boost the amount of data you can gather for
                                    your marketing efforts? Write into us with
                                    any questions you may have and our team will
                                    answer.
                                </p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Prefer to get a demo? Book one below
                                </p>
                                <div className="mt-6 flex justify-start">
                                    <a
                                        href="https://gotomarket.tools/book"
                                        type="submit"
                                        className="rounded-md bg-orange-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                                    >
                                        Book a demo
                                    </a>
                                </div>
                                {/* Address, phone, and email sections have been removed */}
                            </div>
                            <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600"></dl>
                        </div>
                    </div>
                    <div className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                <div>
                                    <label
                                        htmlFor="firstName"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        First name
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-corporate sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="lastName"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        Last name
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            name="lastName"
                                            id="lastName"
                                            autoComplete="family-name"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-corporate sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        Email
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            autoComplete="email"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-corporate sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label
                                        htmlFor="phoneNumber"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        Phone number
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="tel"
                                            name="phoneNumber"
                                            id="phoneNumber"
                                            autoComplete="tel"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-corporate sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label
                                        htmlFor="message"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        Message
                                    </label>
                                    <div className="mt-2.5">
                                        <textarea
                                            name="message"
                                            id="message"
                                            rows={4}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-corporate sm:text-sm sm:leading-6"
                                            defaultValue={""}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 flex justify-end">
                                <button
                                    type="submit"
                                    className="rounded-md bg-corporate px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-corporate focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-corporate"
                                    disabled={isLoading} // Disable button while loading
                                >
                                    {isLoading ? "Sending..." : "Send message"}{" "}
                                    {/* Change button text based on loading state */}
                                </button>
                            </div>
                            {/* Display submission status message */}
                            {submissionStatus === "success" && (
                                <p className="text-green-500 mt-3">
                                    Email sent successfully!
                                </p>
                            )}
                            {submissionStatus === "error" && (
                                <p className="text-red-500 mt-3">
                                    Failed to send email. Please try again
                                    later.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
