import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./Pages/Error";
import Home from "./Pages/Home";
import Pricing from "./Pages/Pricing";
import Features from "./Pages/Features";
import Book from "./Pages/Book";
import TagManager from "react-gtm-module";
import Contact from "./Pages/Contact";
import Blog from "./Pages/Blog";
import Create from "./Pages/Create";
import Maximizing from "./Pages/Posts/maximizing-sales-and-customer-loyalty";
import NavigatingCompetition from "./Pages/Posts/navigating-competition";
import PackingSlips from "./Pages/Posts/packing-slip-approach";
import ExternalTraffic from "./Pages/Posts/external-traffic";
import Careers from "./Pages/Careers";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import AmazonA10Algorithm from "./Pages/Posts/amazon-a10-algorithm";

const tagManagerArgs = {
    gtmId: "GTM-TS7MSSM",
};

TagManager.initialize(tagManagerArgs);

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: "pricing",
                element: <Pricing />,
            },
            {
                path: "features",
                element: <Features />,
            },
            {
                path: "create",
                element: <Create />,
            },
            {
                path: "blog",
                element: <Blog />,
            },
            {
                path: "blog/maximizing-sales-and-customer-loyalty",
                element: <Maximizing />,
            },
            {
                path: "blog/navigating-competition",
                element: <NavigatingCompetition />,
            },
            {
                path: "blog/packing-slip-approach",
                element: <PackingSlips />,
            },
            {
                path: "blog/external-traffic",
                element: <ExternalTraffic />,
            },
            {
                path: "blog/amazon-a10-algorithm",
                element: <AmazonA10Algorithm />,
            },
            {
                path: "book",
                element: <Book />,
            },
            {
                path: "careers",
                element: <Careers />,
            },
            {
                path: "terms",
                element: <Terms />,
            },
            {
                path: "privacy",
                element: <Privacy />,
            },
            {
                path: "contact",
                element: <Contact />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
