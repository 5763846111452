import { Outlet } from "react-router-dom";
import Navigation from "./Components/Navigation";
import CallToAction from "./Components/CallToAction";

function App() {
    return (
        <>
            <Navigation />
            <Outlet />
            <CallToAction />
        </>
    );
}

export default App;
