import { useEffect } from "react";
import TagManager from "react-gtm-module";
import FeaturesComponent from "../Components/Features";
import FeaturesPanel3 from "../Components/FeaturesPanel3";
import FeaturesPanel4 from "../Components/FeaturesPanel4";
import FeaturesPanel5 from "../Components/FeaturesPanel5";
import {
    ArrowRightIcon,
    ChartBarIcon,
    CursorArrowRaysIcon,
    PencilIcon,
    QrCodeIcon,
} from "@heroicons/react/24/outline";

export default function Features() {
    useEffect(() => {
        document.title = "Features - gotomarket.tools";
    }, []);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                page: "Features",
            },
        });
    }, []);

    return (
        <div>
            <div
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[190deg] bg-gradient-to-tr from-[#43749D] to-[#43749D] opacity-10 sm:left-[calc(10%)] sm:w-[242.1875rem]"
                    style={{
                        clipPath:
                            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                />
            </div>
            <div className="my-16 p-4 sm:p-0 flex flex-col items-center justify-center">
                <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-corporate/10 hover:ring-corporate/20">
                    Features Offered by GoToMarket.Tools
                </div>
                <div className="mx-auto max-w-6xl text-center">
                    <h1 className="mt-2 text-center text-5xl font-bold tracking-tight sm:text-6xl lg:text-7xl text-gray-900">
                        The Features You Need To Supercharge Your Amazon Rank
                    </h1>
                </div>
                <p className="mx-auto mt-6 max-w-lg text-center text-xl text-gray-700 sm:max-w-3xl leading-loose">
                    Utilize GoToMarket Tools to easily generate high-converting
                    landing pages, QR codes, and magic links.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <a
                        href="https://app.gotomarket.tools/signup"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-12 py-6 text-base font-medium text-white shadow-sm hover:bg-orange-700"
                    >
                        Sign Up
                    </a>
                    <a
                        href="/create"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center rounded-md bg-white px-12 py-6 text-base font-medium text-gray-900  hover:bg-gray-50 border-2 border-gray-100"
                    >
                        Create Your Landing Page{" "}
                        <span aria-hidden="true" className="ml-2">
                            →
                        </span>
                    </a>
                </div>
            </div>
            <div className="mb-16">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <ul
                        role="list"
                        className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
                    >
                        <li>
                            <div className="aspect-[3/2] w-full rounded-2xl bg-white border-2 border-gray-100 p-12">
                                <img
                                    src="/img/globe1.svg"
                                    className="h-12 w-12"
                                />
                                <h3 className="text-2xl font-bold mt-4">
                                    Landing Pages
                                </h3>
                                <p className="mt-2">
                                    Fully-responsive, high-converting coupon
                                    code landing pages
                                </p>
                                <a
                                    href="#lp"
                                    className="mt-4 flex text-corporate font-bold text-lg"
                                >
                                    Landing Page Features{" "}
                                    <ArrowRightIcon className="flex h-5 w-5 mt-1 ml-2" />
                                </a>
                            </div>
                        </li>
                        <li>
                            <div className="aspect-[3/2] w-full rounded-2xl bg-white border-2 border-gray-100 p-12">
                                <img src="/img/qr1.svg" className="h-12 w-12" />
                                <h3 className="text-2xl font-bold mt-3">
                                    QR Codes
                                </h3>
                                <p className="mt-2">
                                    Lightning-fast, with the ability to pixel
                                    pass-through traffic
                                </p>
                                <a
                                    href="#qr"
                                    className="mt-4 flex text-corporate font-bold text-lg"
                                >
                                    QR Code Features{" "}
                                    <ArrowRightIcon className="flex h-5 w-5 mt-1 ml-2" />
                                </a>
                            </div>
                        </li>
                        <li>
                            <div className="aspect-[3/2] w-full rounded-2xl bg-white border-2 border-gray-100 p-12">
                                <img
                                    src="/img/link1.svg"
                                    className="h-12 w-12"
                                />
                                <h3 className="text-2xl font-bold mt-2">
                                    Magic Links
                                </h3>
                                <p className="mt-2">
                                    Easy-to-type short codes with deep-linking
                                    capabilities
                                </p>
                                <a
                                    href="#ml"
                                    className="mt-4 flex text-corporate font-bold text-lg"
                                >
                                    Magic Link Features{" "}
                                    <ArrowRightIcon className="flex h-5 w-5 mt-1 ml-2" />
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="bg-white px-6 py-24 sm:py-32 lg:px-8" id="lp">
                <div className="mx-auto max-w-2xl text-center">
                    <p className="text-base font-semibold leading-7 text-corporate">
                        Fully-responsive, high-converting
                    </p>
                    <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                        Landing Pages
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Grow your Amazon sales and rank by utilizing an external
                        landing page from GoToMarket.Tools. Learn more below.
                    </p>
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8 py-8">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6 lg:gap-24">
                        <img src="/img/easycreate.png" />
                        <div className="flex items-center">
                            <div>
                                <p className="text-base font-semibold leading-7 text-corporate flex items-center">
                                    <CursorArrowRaysIcon className="h-6 w-6 mr-2" />
                                    One-Click Create
                                </p>
                                <h3 className="text-6xl font-bold mt-2">
                                    Create
                                </h3>
                                <p className="mt-4">
                                    All you need is an ASIN to create a
                                    fully-functioning landing page
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-8">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6 lg:gap-24">
                        <div className="flex items-center">
                            <div>
                                <p className="text-base font-semibold leading-7 text-corporate flex">
                                    <PencilIcon className="h-6 w-6 mr-2" />
                                    Full-edit capabilities
                                </p>
                                <h3 className="text-6xl font-bold mt-2">
                                    Customize
                                </h3>
                                <p className="mt-4">
                                    Edit all aspects of your landing page, from
                                    your images to the price, you can do it all
                                    on GoToMarket.Tools.
                                </p>
                            </div>
                        </div>
                        <img src="/img/customize.png" />
                    </div>
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-8">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6 lg:gap-24">
                        <img src="/img/track.png" />
                        <div className="flex items-center">
                            <div>
                                <p className="text-base font-semibold leading-7 text-corporate flex items-center">
                                    <ChartBarIcon className="h-6 w-6 mr-2" />
                                    Monitor landing page traffic
                                </p>
                                <h3 className="text-6xl font-bold mt-2">
                                    Track
                                </h3>
                                <p className="mt-4">
                                    Landing pages come with built-in analytics,
                                    along with the ability to embed more
                                    advanced analytics platforms so you can
                                    monitor traffic to your landing page.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FeaturesPanel4 />
            <FeaturesPanel5 />
        </div>
    );
}
