import { useEffect } from "react";
import PricingComponent from "../Components/PricingComponent";
import TagManager from "react-gtm-module";

export default function Pricing() {
    useEffect(() => {
        document.title = "Pricing - gotomarket.tools";
    }, []);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                page: "Pricing",
            },
        });
    }, []);

    return (
        <div className="bg-white">
            <PricingComponent />
        </div>
    );
}
