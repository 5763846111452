export default function Careers() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-5xl text-center my-16 sm:my-48 p-4 sm:p-0">
                <h2 className="text-base font-semibold leading-7 text-corporate">
                    Careers
                </h2>
                <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl text-gray-800">
                    Work For An Amazing Company
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                    Looking to make a positive impact on industry-leading
                    eCommerce seller's bottom lines? Apply today!
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <span className="relative z-10 rounded-md bg-gray-100 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
                        Open Positions
                    </span>
                </div>
                <div className="relative mt-12">
                    <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                    >
                        <div className="w-full border-t border-gray-300" />
                    </div>
                </div>
                <div className="mt-24 flex items-center justify-center gap-x-6">
                    <span className="relative z-10 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
                        No Open Positions At This Time
                    </span>
                </div>
            </div>
        </div>
    );
}
