import {
    UserGroupIcon,
    CurrencyDollarIcon,
    ChartPieIcon,
    LinkIcon,
    ShieldCheckIcon,
    DevicePhoneMobileIcon,
} from "@heroicons/react/24/outline";

const features = [
    {
        name: "User Engagement",
        description:
            "Engage users instantly by providing quick access to your content with a simple scan.",
        icon: UserGroupIcon,
    },
    {
        name: "Cost-Effective",
        description:
            "Reduce printing costs and update your digital content without changing your physical QR codes.",
        icon: CurrencyDollarIcon,
    },
    {
        name: "Analytics Tracking",
        description:
            "Track scans and gather valuable data on user interaction and campaign performance.",
        icon: ChartPieIcon,
    },
    {
        name: "Direct Linking",
        description:
            "Connect users directly to any URL, video, or social media page to increase your online traffic.",
        icon: LinkIcon,
    },
    {
        name: "Security and Control",
        description:
            "Maintain control over the content your QR code points to with the ability to update it anytime.",
        icon: ShieldCheckIcon,
    },
    {
        name: "Mobile Optimization",
        description:
            "Reach mobile users effectively, as QR codes are designed for quick access through smartphones.",
        icon: DevicePhoneMobileIcon,
    },
];

export default function FeaturesPanel4() {
    return (
        <div className="bg-gray-100 py-24 sm:py-32" id="qr">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <img
                        src="/img/qr.svg"
                        className="h-12 w-12 mb-6 text-corporate"
                        alt="QR Code"
                    />
                    <h2 className="text-base font-semibold leading-7 text-corporate">
                        Seamless Connectivity
                    </h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Enhance Your Marketing with QR Codes
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-900">
                        Integrate QR codes into your marketing strategy for a
                        modern, user-friendly experience.
                    </p>
                </div>
                <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-900 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative pl-9">
                            <dt className="inline font-semibold text-gray-900">
                                <feature.icon
                                    className="absolute -left-1 top-1 h-6 w-6 text-corporate"
                                    aria-hidden="true"
                                />
                                {feature.name}
                            </dt>{" "}
                            <dd className="inline">{feature.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    );
}
