import React, { useState, useEffect } from "react";

export default function CountdownTimer({ initialTime, pageId, uuid }) {
    const [timeLeft, setTimeLeft] = useState(initialTime);

    useEffect(() => {
        setTimeLeft(initialTime);

        const countdown = setInterval(() => {
            setTimeLeft((timeLeft) => {
                if (timeLeft >= 1) {
                    return timeLeft - 1;
                }

                clearInterval(countdown);
                return 0;
            });
        }, 1000);

        return () => {
            clearInterval(countdown);
        };
    }, [pageId, initialTime]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    const formattedTimeLeft = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;

    return (
        <span>
            Landing page expires in:{" "}
            <span className="text-yellow-300">{formattedTimeLeft}</span>. Enter
            your email above to claim this landing page.
        </span>
    );
}
