import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { CheckIcon, PhoneIcon } from "@heroicons/react/20/solid";
import FeaturePanel from "../Components/FeaturePanel";
import { Link } from "react-router-dom";

const features = [
    {
        name: "Trigger Amazon's Algorithm",
        description:
            "Driving external traffic to your product detail page triggers Amazon's A9 algorithm which rewards your listing with higher organic ranking",
    },
    {
        name: "Grow Your Customer Database",
        description:
            "Your customer provides their first name, last name, email address, and phone number in exchange for their coupon code",
    },
    {
        name: "Earn 10% Back On Every Sale",
        description:
            "External traffic driven from GoToMarket's landing pages that results in a sale on Amazon qualifies you for 10% back on the sale price through Amazon's Brand Referral Bonus program",
    },
    {
        name: "Get More Sales",
        description:
            "When you distribute coupon codes, you are driving more sales to your product on Amazon, which in return boosts your ranking within Amazon",
    },
    {
        name: "Pixel Landing Page Traffic",
        description:
            "Build your remarketing lists on Facebook, Google, etc. by pixelling your incoming customer traffic to your landing page",
    },
    {
        name: "Reporting",
        description:
            "See how many people are visiting your landing pages to gauge the effectiveness of your landing page campaigns.",
    },
];

export default function Home() {
    useEffect(() => {
        document.title = "gotomarket.tools - Your Amazon Landing Page Solution";
    }, []);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                page: "Home",
            },
        });
    }, []);

    return (
        <div>
            <main>
                <div>
                    {/* Hero card */}
                    <div className="relative">
                        <div
                            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                            aria-hidden="true"
                        >
                            <div
                                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[190deg] bg-gradient-to-tr from-[#43749D] to-[#43749D] opacity-10 sm:left-[calc(10%)] sm:w-[242.1875rem]"
                                style={{
                                    clipPath:
                                        "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                                }}
                            />
                        </div>
                        <div className="mt-6 sm:mt-12 mb-8 p-8 sm:p-8 flex flex-col items-center justify-center">
                            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-corporate/10 hover:ring-corporate/20">
                                Amazon Product Ranking Tool
                            </div>
                            <div className="mx-auto max-w-6xl text-center">
                                <h1 className="mt-2 text-center text-5xl font-bold tracking-tight leading-tight sm:text-6xl lg:text-7xl text-gray-900">
                                    Boost Amazon Product Rank
                                </h1>
                            </div>
                            <p className="mx-auto mt-6 max-w-lg text-center text-xl text-gray-700 sm:max-w-3xl leading-loose">
                                Increase your Amazon sales and product rank by
                                distributing Amazon coupon codes through an
                                external landing page powered by
                                GoToMarket.Tools. Generate your free landing
                                page below.
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <a
                                    href="https://app.gotomarket.tools/landingpage/6543df6750e209263db13738"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center text-center justify-center rounded-md border border-transparent bg-orange-600 px-6 py-6 text-base font-medium text-white shadow-sm hover:bg-orange-700"
                                >
                                    View Demo Landing Page
                                </a>
                                <a
                                    href="/create"
                                    rel="noopener noreferrer"
                                    className="flex items-center justify-center rounded-md bg-white px-6 py-6 text-base font-medium text-gray-900  hover:bg-gray-50 border-2 border-gray-200"
                                >
                                    Create Your Own{" "}
                                    <span aria-hidden="true" className="ml-2">
                                        →
                                    </span>
                                </a>
                            </div>
                            <div className="mt-6 flex items-center justify-center gap-x-6">
                                <Link
                                    to="/book"
                                    className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-2 ring-corporate/20 hover:ring-corporate/20"
                                >
                                    <div className="flex items-center justify-center">
                                        <span className="flex">
                                            <PhoneIcon className="flex h-5 w-5 mr-1" />
                                        </span>
                                        <span className="flex text-corporate text-base">
                                            Or Book A Demo
                                        </span>
                                    </div>
                                </Link>
                            </div>

                            <img
                                src="/img/lp_5.png"
                                alt="App screenshot"
                                className="max-w-6xl rounded-md mt-12 w-full"
                            />
                        </div>
                        <div className="overflow-hidden bg-gray-100 pt-24 pb-12 sm:py-16">
                            <div className="mx-auto max-w-full px-6 lg:px-8">
                                <div className="mx-auto max-w-5xl text-center">
                                    <h2 className="text-base font-semibold leading-7 text-corporate">
                                        How Does It Work?
                                    </h2>
                                    <p className="mt-2 text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl text-gray-900">
                                        Drive External Traffic To Your
                                        GoToMarket.Tools Landing Page
                                    </p>
                                </div>
                                <p className="mx-auto mt-6 max-w-4xl text-center text-lg leading-8 text-gray-900">
                                    When running a coupon code campaign, direct
                                    ad and promotional traffic through a
                                    GoToMarket.Tools landing page. Once your
                                    customer is on the coupon code landing page,
                                    they will claim their unique or multi-use
                                    coupon code and will be directed off to
                                    Amazon to use it. This allows you to collect
                                    vital customer information that you can use
                                    in future marketing campaigns, such as
                                    customer's first name, last name, email, and
                                    phone, in addition to pixelling incoming
                                    traffic for use in remarketing campaigns on
                                    Facebook, Google, Instagram, TikTok, etc.
                                </p>
                                <div className="my-12">
                                    <img
                                        src="/img/Reels_Individual.png"
                                        alt="Product screenshot"
                                        className="max-w-7xl m-auto w-full block md:hidden"
                                    />
                                    <img
                                        src="/img/Reels.png"
                                        alt="Product screenshot"
                                        className="max-w-7xl m-auto w-full hidden md:block"
                                    />
                                </div>
                                <p className="mx-auto mt-6 max-w-4xl text-center text-lg leading-8 text-gray-900">
                                    In addition to collecting customer
                                    information, using a coupon code landing
                                    page also allows brands referred in Amazon's
                                    Brand Registry to utilize their Brand
                                    Referral Bonus, which allows you to get up
                                    to 10% back on each sale made through
                                    traffic driven from an external traffic
                                    source.
                                </p>
                            </div>
                            <div className="mt-10 px-4 flex items-center justify-center gap-x-6">
                                <a
                                    href="https://app.gotomarket.tools/landingpage/6543df6750e209263db13738"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center text-center justify-center rounded-md border border-transparent bg-orange-600 px-6 py-6 text-base font-medium text-white shadow-sm hover:bg-orange-700"
                                >
                                    View Demo Landing Page
                                </a>
                                <a
                                    href="/create"
                                    rel="noopener noreferrer"
                                    className="flex items-center justify-center rounded-md bg-white px-6 py-6 text-base font-medium text-gray-900  hover:bg-gray-50 border-2 border-gray-200"
                                >
                                    Create Your Own{" "}
                                    <span aria-hidden="true" className="ml-2">
                                        →
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className="bg-gray-900 py-24 sm:py-16">
                            <div className="mx-auto max-w-full px-12 lg:px-16">
                                <div className="mx-auto max-w-5xl text-center">
                                    <h2 className="text-base font-semibold leading-7 text-corporate">
                                        Why Does It Work?
                                    </h2>
                                    <p className="mt-2 text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl text-gray-100">
                                        More External Traffic = Higher Rank
                                    </p>
                                </div>
                                <p className="mx-auto mt-6 max-w-4xl text-center text-lg leading-8 text-gray-200">
                                    Amazon's A9 algorithm is prioritizing
                                    external traffic as a primary factor in
                                    organic ranking on Amazon. Utilizing a
                                    GoToMarket.Tools landing page targets 3 main
                                    ranking factors, including Impressions,
                                    Conversion Rate, and External Traffic.
                                </p>
                                <div className="my-12">
                                    <img
                                        src="/img/A9_Graphic.png"
                                        alt="Product screenshot"
                                        className="max-w-3xl m-auto w-full"
                                    />
                                </div>
                                <p className="mx-auto mt-6 max-w-4xl text-center text-lg leading-8 text-gray-200">
                                    When you drive external traffic to your
                                    Amazon listing through a GoToMarket.Tools
                                    landing page, you get 1.) an impression, 2.)
                                    since you are offering a coupon code and
                                    that coupon code has to be claimed by your
                                    customer, it is very likely that you will be
                                    getting a conversion, and 3.) since the
                                    traffic is coming from an external source,
                                    this hits the "external traffic" node of
                                    Amazon's algorithm. All of these factors
                                    combined are a potent combination for
                                    ranking high and fast on Amazon.
                                </p>
                            </div>
                        </div>
                        {/* <div className="bg-white py-24 sm:py-16">
                            <div className="mx-auto max-w-full px-12 lg:px-16">
                                <div className="mx-auto max-w-5xl text-center">
                                    <h2 className="text-base font-semibold leading-7 text-corporate">
                                        How Do I Get Traffic To My Landing Page?
                                    </h2>
                                    <p className="mt-2 text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl text-gray-900">
                                        Promote Your Coupon To Your Customers
                                    </p>
                                </div>
                                <p className="mx-auto mt-6 max-w-4xl text-center text-lg leading-8 text-gray-900">
                                    In order to get the algorithm-enhancing
                                    benefits, your customer should be directed
                                    through your GoToMarket.Tools landing page
                                    before they go off to Amazon. Your
                                    GoToMarket.Tools landing page should be
                                    promoted on all of your social media
                                    channels, by email, and even through popular
                                    ad platforms.
                                </p>
                                <div className="my-12">
                                    <img
                                        src="/img/funnel_black.svg"
                                        alt="Product screenshot"
                                        className="max-w-3xl m-auto"
                                    />
                                </div>
                            </div>
                        </div>
                        <FeaturePanel /> */}
                        <div className="bg-white py-24 sm:py-32">
                            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-corporate">
                                            What do I gain?
                                        </h2>
                                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                            A Host Of Benefits
                                        </p>
                                        <p className="mt-6 text-base leading-7 text-gray-600">
                                            From building a first-party
                                            marketing database to activating
                                            Amazon's A9 algorithm for your
                                            products, the benefits of GoToMarket
                                            are many.
                                        </p>
                                    </div>
                                    <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                                        {features.map((feature) => (
                                            <div
                                                key={feature.name}
                                                className="relative pl-9"
                                            >
                                                <dt className="font-semibold text-gray-900">
                                                    <CheckIcon
                                                        className="absolute left-0 top-1 h-5 w-5 text-corporate"
                                                        aria-hidden="true"
                                                    />
                                                    {feature.name}
                                                </dt>
                                                <dd className="mt-2">
                                                    {feature.description}
                                                </dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
